<template>
  <div>
    <el-row :gutter="15" justify="center">
      <el-col :xs="24" :sm="24" :md="17" :xl="17">
        <div class="card-container">
          <el-table
            v-if="form.model.voucher.voucherDetails.length"
            :data="form.model.voucher.voucherDetails"
            style="width: 100%"
          >
            <el-table-column :label="$tc('company')" prop="companyName"> </el-table-column>
            <el-table-column :label="$tc('description')" prop="voucherDetailDescription">
            </el-table-column>
            <el-table-column :label="$tc('plan')" prop="voucherDetailPlanAmount">
              <template slot-scope="scope">
                {{ scope.row.voucherDetailPlanAmount | formatToCurrencyWithIsoCode }}
              </template>
            </el-table-column>
            <el-table-column :label="$tc('feature')" prop="voucherDetailPlanFeatureAmount">
              <template slot-scope="scope">
                {{ scope.row.voucherDetailPlanFeatureAmount | formatToCurrencyWithIsoCode }}
              </template>
            </el-table-column>
            <el-table-column :label="$tc('addOn')" prop="voucherDetailAmountTotal">
              <template slot-scope="scope">
                {{ scope.row.voucherDetailAddOnAmount | formatToCurrencyWithIsoCode }}
              </template>
            </el-table-column>
            <el-table-column :label="$tc('total')" prop="voucherDetailAmountTotal">
              <template slot-scope="scope">
                {{ scope.row.voucherDetailAmountTotal | formatToCurrencyWithIsoCode }}
              </template>
            </el-table-column>
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-card v-if="props.row.addOns.length > 0" class="box-card">
                  <div slot="header" class="clearfix">
                    <span>{{ $tc('addOn') }}</span>
                  </div>
                  <table width="100%">
                    <col style="width: 40%" />
                    <col style="width: 40%" />
                    <col style="width: 20%; text-align: right" />
                    <thead>
                      <tr>
                        <th align="left">
                          {{ $tc('name', 1) }}
                        </th>
                        <th align="right">
                          {{ $tc('iva') }}
                        </th>
                        <th align="right">
                          {{ $tc('price') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(itemAddOn, indexAddOn) in props.row.addOns" :key="indexAddOn">
                        <td align="left">
                          {{ itemAddOn.addOnName }}
                        </td>
                        <td align="right">
                          {{ itemAddOn.addOnIvaValue | formatToCurrencyWithIsoCode }}
                        </td>
                        <td align="right">
                          {{ itemAddOn.addOnPrice | formatToCurrencyWithIsoCode }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <h3>{{ $tc('total') }}</h3>
                        </td>
                        <td align="right">
                          <h3>
                            {{ props.row.voucherDetailAddOnPrice | formatToCurrencyWithIsoCode }}
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </el-card>
                <el-card
                  v-if="
                    props.row.features.filter(
                      itemFilter =>
                        itemFilter.featureAddonQuantity === 'true' ||
                        +itemFilter.featureAddonQuantity
                    ).length > 0
                  "
                  class="box-card"
                >
                  <div slot="header" class="clearfix">
                    <span>{{ $tc('feature') }}</span>
                  </div>
                  <table width="100%">
                    <col style="width: 40%" />
                    <col style="width: 20%; text-align: center" />
                    <col style="width: 40%" />
                    <thead>
                      <tr>
                        <th align="left">
                          {{ $tc('name', 1) }}
                        </th>
                        <th>{{ $tc('quantity') }}</th>
                        <th>{{ $tc('priceUnit') }}</th>
                        <th>{{ $tc('total') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(itemFeature, indexFeature) in props.row.features.filter(
                          itemFilter =>
                            itemFilter.featureAddonQuantity === 'true' ||
                            +itemFilter.featureAddonQuantity
                        )"
                        :key="indexFeature"
                      >
                        <td align="left">
                          {{ itemFeature.featureName }}
                        </td>
                        <td align="center">
                          {{
                            itemFeature.featureAddonQuantity === 'true'
                              ? '1'
                              : itemFeature.featureAddonQuantity
                          }}
                        </td>
                        <td align="right">
                          {{ itemFeature.featureUnitPriceCustom | formatToCurrencyWithIsoCode }}
                        </td>
                        <td align="right">
                          {{ itemFeature.featureBilledValue | formatToCurrencyWithIsoCode }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <h3>{{ $tc('total') }}</h3>
                        </td>
                        <td align="right">
                          <h3>
                            {{
                              props.row.voucherDetailPlanFeaturePrice | formatToCurrencyWithIsoCode
                            }}
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </el-card>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="7" :xl="7">
        <div class="card-container">
          <div v-cus-loading="form.loading" class="card-column col-select">
            <div class="card">
              <div class="step">
                <div class="number">
                  <span>
                    <i class="fad fa-credit-card"> </i>
                  </span>
                </div>
                <div class="title">
                  <span>{{ form.model.voucher.voucherConsecutive }}</span>
                </div>
              </div>
              <div class="content">
                <div class="left">
                  <div class="purchase">
                    <div class="purchase-image">
                      <img
                        :alt="$tc('hello')"
                        class="image"
                        src="/img/transactions/credit_card_payment.svg"
                        height="100"
                      />
                    </div>
                  </div>
                  <div class="totals">
                    <table width="100%">
                      <col style="width: 35%" />
                      <col style="width: 65%" />
                      <tbody>
                        <tr class="subtitle">
                          <td align="left">
                            {{ $tc('plan', 0) }}
                          </td>
                          <td class="sub-price" align="right">
                            {{ form.model.voucher.voucherTaxBase | formatToCurrencyWithIsoCode }}
                          </td>
                        </tr>
                        <tr class="subtitle">
                          <td align="left">
                            {{ $tc('iva') }}
                          </td>
                          <td class="sub-price" align="right">
                            {{ form.model.voucher.voucherIvaValue | formatToCurrencyWithIsoCode }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="final">
                    <table width="100%">
                      <col style="width: 35%" />
                      <col style="width: 65%" />
                      <tbody>
                        <tr>
                          <td class="title" align="left">
                            <span class="description">
                              {{ $tc('total', 0) }}
                              <i class="fad fa-calendar-alt"> </i>
                              /
                              <small>
                                {{ $tc(toCamelCase(form.model.voucher.voucherRecurrence)) }}
                              </small>
                            </span>
                          </td>
                          <td class="sub-price" align="right">
                            {{
                              form.model.voucher.voucherAmountTotal | formatToCurrencyWithIsoCode
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="right reviewed">
                  <div class="billing">
                    <table width="100%">
                      <col style="width: 35%" />
                      <col style="width: 65%" />
                      <tbody class="payment-summary">
                        <tr>
                          <td class="payment-title" align="left">{{ $tc('dateDue') }}:</td>
                          <td class="payment-description" align="left">
                            {{ form.model.voucher.voucherDateDue | formatToDate }}
                          </td>
                        </tr>
                        <tr>
                          <td class="payment-title" align="left">
                            {{ $tc('description', 2) }}
                          </td>
                          <td class="payment-description" align="left">
                            {{ form.model.voucher.voucherDescription }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="center complete">
                    <div class="center">
                      <div class="container-btn">
                        <div v-if="model.voucherMessage.length > 0" class="left">
                          <el-alert type="error" :title="$tc('paymentError')">
                            <span>{{ model.voucherMessage }}</span>
                          </el-alert>
                        </div>
                      </div>
                    </div>
                    <div>
                      <el-button size="mini" class="btn btn-select" @click="onPayment()">
                        <span>
                          {{ $tc('continue', 2) }}
                          <i class="fad fa-credit-card-front"> </i>
                        </span>
                      </el-button>
                    </div>
                    <div>
                      <el-button
                        size="mini"
                        type="text"
                        @click="onViewPlaceToPayPayments(form.model.voucher)"
                      >
                        {{ $tc('viewPayments') }}
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="footer">
                  <span class="sub">
                    <small>
                      <em>
                        <address>
                          <a href="mailto:#">facturacion@aliaddo.com</a>
                        </address>
                      </em>
                    </small>
                  </span>
                  <div class="" style="font-weight: bold">
                    <el-row :gutter="20">
                      <el-col :xs="24" :md="8">
                        <img src="/img/place_to_pay/MASTERCARD.png" alt="Mastercard" height="20" />
                        <img src="/img/place_to_pay/PSE.png" alt="Pse" height="20" />
                        <img src="/img/place_to_pay/VISA.png" alt="Visa" height="20" />
                        <img src="/img/place_to_pay/AMEX.png" alt="American Express" height="20" />
                      </el-col>
                      <el-col :xs="24" :md="8">
                        <a href="https://aliaddo.com/" target="_blank" rel="noopener noreferrer">
                          <img src="@/assets/img/svg/logo-aliaddo.svg" height="30" />
                        </a>
                      </el-col>
                      <el-col :xs="24" :md="8">
                        <a
                          href="https://www.placetopay.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src="/img/place_to_pay/Logo_PlacetoPay.png" height="25" />
                        </a>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- #region  dialogs  -->
    <div>
      <!-- #region  payment  -->
      <el-dialog
        :close-on-click-modal="false"
        :loading="dialog.payment.loading"
        :visible.sync="dialog.payment.visible"
        width="95%"
        append-to-body
        @opened="onOpenedPayment"
      >
        <payment
          v-if="dialog.payment.visible"
          ref="payment"
          :is-embedded="$route.params.isEmbedded === 'true'"
          :model="form.model"
          source="Voucher"
        ></payment>
      </el-dialog>
      <!-- #endregion  -->
      <!-- #region  payments  -->
      <el-dialog
        :loading="dialog.payments.loading"
        :visible.sync="dialog.payments.visible"
        width="95%"
        append-to-body
      >
        <payments v-if="dialog.payments.visible" :voucher-id="form.model.voucher.id"></payments>
      </el-dialog>
      <!-- #endregion  -->
    </div>
    <!-- #endregion  -->
  </div>
</template>

<script>
/* End of Vendors
-------------------------------------------------- */
/* app
-------------------------------------------------- */
import { mapFilters } from '@/filters/map-filters'
import dialog, { showDialog } from '@/views/common/dialog'
import form from '@/views/common/form'
import model from '@/views/placeToPay/model'
import { setTimeout } from 'timers'

/* End of app
-------------------------------------------------- */

export default {
  name: 'VoucherPayment',
  components: {
    payment: _ => import('@/views/placeToPay/payment'),
    payments: _ => import('@/views/placeToPay/payments')
  },
  data() {
    return {
      form: form({ model }),
      dialog: {
        askedQuestionsElectronicPayments: {
          ...dialog()
        },
        payment: {
          ...dialog()
        },
        payments: {
          ...dialog()
        }
      },
      model: {
        company: {},
        voucherMessage: ''
      }
    }
  },
  methods: {
    ...mapFilters(['toCamelCase']),
    showDialog,
    onOpenedPayment() {
      const _this = this
      setTimeout(_ => _this.$refs.payment.fillForm(_this.form.model), 1000)
    },
    onPayment() {
      const _this = this
      _this.showDialog('payment')
    },
    onViewPlaceToPayPayments() {
      this.showDialog('payments')
    },
    getData() {
      const _this = this
      let uri = `vouchers/${_this.serviceId}/${_this.$route.params.companyId}/first/open`
      if (_this.$route.params.placeToPayReferenceId) {
        uri = `vouchers/${_this.$route.params.placeToPayReferenceId}`
      }
      _this.form.loading = true
      _this.$http
        .get(uri)
        .then(response => {
          _this.form.model.voucher = response.body
          if (_this.form.model.voucher) {
            return _this.$http.get(
              `companies/${_this.$route.params.companyId}/${_this.serviceId}/findByService`
            )
          }
          _this.$message.error(_this.$tc('noVoucherFound'))
        })
        .catch(error => {
          if (error.body) {
            _this.model.voucherMessage = error.body

            return _this.model.voucherMessage
          }
        })
        .then(response => {
          if (response.body) {
            _this.form.model.company = response.body
            if (_this.form.model.voucher && _this.form.model.voucher.id) {
              // _this.onPayment()
            } else {
              _this.$message.error(_this.$tc('noFoundVoucherMessage'))
              _this.model.voucherMessage = _this.$tc('noFoundVoucherMessage')
            }
          }
        })
        .finally(() => {
          _this.form.loading = false
        })
    }
  },
  computed: {
    serviceId: _this => {
      if (_this.$route.params.service === 'fe') {
        return 1
      }
      if (_this.$route.params.service === 'aliaddo') {
        return 2
      }
      if (_this.$route.params.service === 'isv') {
        return 7
      }
      if (_this.$route.params.service === 'hrm-full') {
        return 10
      }
      if (_this.$route.params.service === 'hrm-lite') {
        return 11
      }
      if (_this.$route.params.service === 'hrm-isv') {
        return 12
      }

      return _this.$route.params.serviceId
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/transactions/personalize.scss';
</style>
